
import React, { useState, useReducer, useEffect, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VariableSizeList } from 'react-window';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from "@mui/material/styles";

import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

import debounce from 'lodash.debounce';

import axios from 'axios';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BackpackIcon from '@mui/icons-material/Backpack';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import CircularProgress from '@mui/material/CircularProgress';

let links = [
    ['Оплата QR/KaspiRed KZ', 'https://docs.google.com/spreadsheets/d/1VVx2y_5j81xfD1t4DN4GCyt9KS4GriKGOIQoqM8qG-4/edit?gid=507904294#gid=507904294'],
    ['Свод пиццерий', 'https://docs.google.com/spreadsheets/d/1UTBIqMDteb2m8nR5mb925jXuVStnvBLuFPc3dkY5lYk/edit#gid=0'],
    ['Свод по меню', 'https://docs.google.com/spreadsheets/d/1DEEr3oW7pYQMvW71wzemmZKDNe9Y8tkry4z66Gh1rsw/edit?gid=1138590522#gid=1138590522'],
    ['Детские комнаты и информация по пиццериям', 'https://docs.google.com/spreadsheets/d/1OELdUjCNQYSn0e9zhSTYFdg9J5Jc66ZkbgYXVsWibwM/edit?gid=530053596#gid=530053596'],
    ['Поставщики', 'https://docs.google.com/spreadsheets/d/1vLXFeOd9fn8Ee-0QjvXK4aACO_leY8kvW70RjHKrSLc/edit?gid=1674554121#gid=1674554121'],
    ['Правила поведения в пиццерии', 'https://docs.google.com/document/d/1EJtkusnzzBFm6Uk07MoyF1SuE7W26yfO18xCJgt7BHQ/edit?tab=t.0#heading=h.jwxbprtsafij'],
    ['Таблица с ошибочными ответами бота', 'https://docs.google.com/spreadsheets/d/1hxubBqwOZ_q08PJxQTYj8hoSUaTDg9BxFsSQksb5hNI/edit?gid=0#gid=0'],
    ['Стандарты пиццерии', 'https://dodopizza.info/standards/?region=cis'],
    ['Вес ингредиентов в пиццах', 'https://docs.google.com/spreadsheets/d/1v1-_eSXyOYJ_W-0Bu7fCEmqqmJuScf9JMdCcS13bt2c/edit?gid=2024484994#gid=2024484994'],
    ['Калорийность и состав', 'https://drive.google.com/file/d/1GWaKPdU7t5URgMkh_X4pJqmyZuGr9FdQ/edit'],
    ['Вопросы и предложения', 'https://pyrus.com/t#uf523616'],
    ['Запись на мастер-класс', 'https://dodokids.ru'],
]

function AppVersionFetcher() {
    const [playVersion, setPlayVersion] = useState(null);
    const [appStoreVersion, setAppStoreVersion] = useState(null);

    const fetchPlayStoreVersion = async (packageName) => {
        try {
            const response = await fetch(`https://dodogenerator.pacificrp.ru/api/playstore?packageName=${packageName}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            setPlayVersion(data.version || "Не удалось получить версию");
        } catch (error) {
            setPlayVersion("Ошибка получения версии");
        }
    };

    const fetchAppStoreVersion = (appId) => {
        const script = document.createElement("script");
        const callbackName = "handleAppStoreResponse";

        window[callbackName] = (data) => {
            if (data.results && data.results[0]) {
                setAppStoreVersion(data.results[0].version || "Версия не найдена");
            } else {
                setAppStoreVersion("Версия не найдена");
            }
            document.body.removeChild(script);
            delete window[callbackName];
        };

        script.src = `https://itunes.apple.com/lookup?id=${appId}&callback=${callbackName}`;
        document.body.appendChild(script);
    };

    useEffect(() => {
        fetchPlayStoreVersion("ru.dodopizza.app");
        fetchAppStoreVersion("894649641");
    }, []);

    return (
        <Paper elevation={3} sx={{ padding: 2, marginTop: '20px' }}>
            <Box mb={2} display="flex" justifyContent="center">
                <Typography variant="caption" align="center">Версии приложения</Typography>
            </Box>
            <Button
                href="https://apps.apple.com/ru/app/id894649641"
                fullWidth
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<AppleIcon />}
                sx={{ marginBottom: 1 }}
            >
                {appStoreVersion ? appStoreVersion : <CircularProgress size={12} />}
            </Button>
            <Button
                href="https://play.google.com/store/apps/details?id=ru.dodopizza.app&hl=ru&gl=US"
                fullWidth
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<AndroidIcon />}
            >
                {playVersion ? playVersion : <CircularProgress size={12} />}
            </Button>
        </Paper>
    );
}


function Links() {

    return (
        <Container>
            <AppVersionFetcher />
            <Paper elevation={3} sx={{ padding: 2, marginTop: "20px" }}>
                <Box mb={2} display="flex" justifyContent="center">
                    <Typography variant="caption" align="center">Ссылки</Typography>
                </Box>
                {
                    links.map((link, i) => {
                        return <Button fullWidth key={i} href={link[1]} target="_blank">{link[0]}</Button>
                    })
                }
            </Paper>

        </Container>
    );
}



export default Links;


