
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';
import { Select, MenuItem, InputLabel, ButtonGroup, Menu, Box, Slider, Tooltip, Container, Checkbox, Chip, FormControlLabel, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, TextField, IconButton, useMediaQuery, Divider, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";


function Promocodes(props) {
    const [selectedPromos, setSelectedPromos] = useState([]);  // Массив выбранных промокодов
    const [country, setCountry] = useState("ru");
    const [conditions, setConditions] = useState(false);
    const [offerType, setOfferType] = useState(true);

    const promos = {
        ru: [
            { label: "10-30% на 14 дней", key: "1", a: "1", text: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней. Действует в заказах всех типов, доставка может быть платной. Активен с комбо, но не работает с другими акциями.", textCondition: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "200-300 рублей на 14 дней", key: "2", a: "2", text: "на 200 рублей, им можно воспользоваться 1 раз в течение 14 дней. Действует в заказах всех типов, доставка может быть платной. Активен с комбо, но не работает с другими акциями.", textCondition: "на 200 рублей, им можно воспользоваться 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "Пицца 25-35 см на 14 дней", key: "3", a: "3", text: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Чтобы им воспользоваться, добавьте пиццу в корзину, а затем примените акцию, стоимость продукта изменится на 1 рубль. Доставка может быть платной. Купон суммируется с комбо, но не работает с другими акциями, а также не распространяется на дополнительные ингредиенты.", textCondition: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Пиццу можно получить за рубль к заказу на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, предложение суммируется с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе с подарочным продуктом должна быть выше минимальной на доставку." },
            //{ label: "Додстер на 14 дней", key: "5", a: "7", text: "на классический додстер — закуску можно получить бесплатно 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё. Додстер нужно добавить в корзину самостоятельно, затем применить купон. Промокод суммируется с комбо, но не работает с другими акциями.", textCondition: "на классический додстер, который можно получить бесплатно 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё. Чтобы купон сработал, сумма заказа вместе с подарочной закуской должна быть выше минимальной на доставку. Промокод суммируется с комбо, но не действует с другими акциями." },
            { label: "100 рублей на год", key: "6", a: "8", text: "на 100 рублей, которым можно воспользоваться 1 раз в течение года. Действует в заказах всех типов, доставка может быть платной. Активен с комбо, но не работает с другими акциями.", textCondition: "на 100 рублей, им можно воспользоваться на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "На сумму со сроком год", key: "7", a: "9", text: "на ХХХ рублей, которым можно воспользоваться 1 раз в течение года. Действует в заказах всех типов, активен с комбо, но не работает с другими акциями. Доставка может быть платной.", textCondition: "" },
            { label: "Пицца за опоздание", key: "8", a: "10", text: "на пиццу 35 см, её можно получить 1 раз в течение года. Предложение действует в любом ресторане России. Чтобы воспользоваться им, добавьте пиццу в корзину, а затем примените акцию. Доставка может быть платной. Купон суммируется с комбо, но не работает с другими предложениями и не действует на дополнительные ингредиенты.", textCondition: "" },
           
            { label: "Довоз (предложение)", key: "20", a: "20", text: "Можем привезти ХХХ в течение часа, также подарим скидку 10% за ожидание. Подойдёт такое решение?", textCondition: "" },
            { label: "Довоз (промокод)", key: "21", a: "21", text: "XXX доставим в течение часа, примите пока промокод на скидку 10%: [ХХХ](dodo://applypromocode/ХХХ). Им можно воспользоваться 1 раз в течение 2 недель. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "" },
            { label: "Довоз", key: "22", a: "22", text: "привезём ХХХ в течение часа, также подарим скидку 10% за ожидание.", textCondition: "" },
           
        ],
        kz: [
            { label: "10-30% на 14 дней", key: "1", a: "1", text: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на скидку ХХ%, его можно применить 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "1100/2000/3000/5700 тенге на 14 дней", key: "2", a: "2", text: "на 1100/2000/3000/5700 тенге, им можно воспользоваться 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 1100/3000/5700 тенге, им можно воспользоваться 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "Пицца 25-35 см на 14 дней", key: "3", a: "3", text: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Пиццу можно получить бесплатно, если её стоимость покрывает минимальную сумму на доставку, если пицца стоит меньше, то нужно будет добрать позиций до этой суммы. При самовывозе и в зале минимальную сумму набирать не нужно. Промокод суммируется с комбо, но не работает с другими акциями.", textCondition: "на пиццу ХХ см, его можно применить 1 раз в течение 14 дней. Пиццу можно получить в подарок к заказу на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, предложение суммируется с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе с подарочным продуктом должна быть выше минимальной на доставку." },
            { label: "11500 тенге на год", key: "5", a: false, text: "на 11500 тенге, которым можно воспользоваться 1 раз в течение года. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 11500 тенге, им можно воспользоваться на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            //{ label: "600 тенге на 14 дней", key: "7", a: "5", text: "на 600 тенге, им можно воспользоваться 1 раз в течение 14 дней. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 600 тенге, им можно воспользоваться 1 раз в течение 14 дней на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "600 тенге на год", key: "8", a: "6", text: "на 600 тенге, которым можно воспользоваться 1 раз в течение года. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "на 600 тенге, им можно воспользоваться на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не действует с другими акциями. Чтобы купон сработал, сумма заказа вместе со скидкой должна быть выше минимальной на доставку." },
            { label: "На сумму со сроком год", key: "9", a: "7", text: "на ХХХ тенге, которым можно воспользоваться 1 раз в течение года. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "" },
            { label: "Пицца за опоздание", key: "10", a: "8", text: "на пиццу 35 см, её можно получить 1 раз в течение года. Предложение действует в любом ресторане Казахстана. Пиццу можно получить бесплатно, если её стоимость покрывает минимальную сумму на доставку, если пицца стоит меньше, то нужно будет добрать позиций до этой суммы. При самовывозе и в зале минимальную сумму набирать не нужно. Предложение суммируется с комбо, но не работает с другими акциями.", textCondition: "" },
           
            { label: "Довоз (предложение)", key: "20", a: "20", text: "Можем привезти ХХХ в течение часа, также подарим скидку 10% за ожидание. Подойдёт такое решение?", textCondition: "" },
            { label: "Довоз (промокод)", key: "21", a: "21", text: "XXX доставим в течение часа, примите пока промокод на скидку 10%: [ХХХ](dodo://applypromocode/ХХХ). Им можно воспользоваться 1 раз в течение 2 недель. Действует на доставку от минимальной суммы, при самовывозе и в ресторане — без неё, активен с комбо, но не работает с другими акциями.", textCondition: "" },
            { label: "Довоз", key: "22", a: "22", text: "привезём ХХХ в течение часа, также подарим скидку 10% за ожидание.", textCondition: "" },
        ]
    };

    const handlePromoChange = (event, values) => {
        setSelectedPromos(values); 

        if (values.length > 0) {
            const promoText = values.map((promo, index, array) => {
                let promoText = conditions ? promo.textCondition : promo.text;
        
                if (promoText !== "") {
                    
                        if (offerType) {
                            if (promo.key !== "20" && promo.key !== "21") {
                                promoText = `Хотим исправиться, отправляем промокод [ХХХ](dodo://applypromocode/ХХХ) ${promoText} Промокод также можно найти в приложении или на сайте ${country === "ru" ? "dodopizza.ru" : "dodopizza.kz"}, в разделе «Акции».`;
                            }
                        } else {
                            if (values.length === 1) 
                                return `${promoText}`;
                            else
                            return promo.key === "22"
                            ? `- ${index === array.length - 1 ? promoText : promoText.replace(/\.$/, '')}${index === array.length - 1 ? "" : ";"}` 
                            : `- промокод ${index === array.length - 1 ? promoText : promoText.replace(/\.$/, '')}${index === array.length - 1 ? "" : ";"}` 
                        }
                    }
                
                return promoText;
            });
        
            let finalText = "";
            if (offerType) {
                finalText = promoText.join("\n");
            } else {
                if (values.length === 1) {
                    finalText = `Предлагаем удобный вариант: промокод ${promoText[0]} \nПримете его?`;
                } else {
                    finalText = `Предлагаем несколько вариантов:\n\n${promoText.join("\n")}\n\nКакое решение будет удобнее?`;
                }
            }
            props.setPromoText(finalText);
        }
        else {
            props.setPromoText("");
        }
    };

    const handleCountryChange = (event) => {
        const newCountry = event.target.checked ? "kz" : "ru";
        setCountry(newCountry);
        if (selectedPromos.length > 0) {
            const updatedPromos = selectedPromos.map(promo => {
                const foundPromo = promos[newCountry].find(p => p.a === promo.key);
                return foundPromo || promo;  
            });
            setSelectedPromos(updatedPromos);
            handlePromoChange(null, updatedPromos);  
        }
    };

    const handleConditionsChange = (event) => {
        setConditions(event.target.checked);
        if (selectedPromos.length > 0) {
            handlePromoChange(null, selectedPromos);
        }
    };

    const handleOfferTypeChange = (event) => {
        setOfferType(event.target.checked);
        if (selectedPromos.length > 0) {
            handlePromoChange(null, selectedPromos);
        }
    };

    useEffect(() => {
        if (selectedPromos.length > 0) {
            handlePromoChange(null, selectedPromos); 
        }
    }, [conditions, offerType, selectedPromos]);

    return (
        <Box sx={{ display: "flex", gap: 2, marginBottom: 2, marginTop: "10px" }}>
            <Autocomplete
                sx={{ width: "850px" }}
                multiple
                limitTags={3}
                options={promos[country]}
                getOptionLabel={(option) => option.label}
                onChange={handlePromoChange}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                renderInput={(params) => <TextField {...params} label="Компенсация" />}
            />
            <FormControlLabel
                control={<Checkbox checked={country === "kz"} onChange={handleCountryChange} />}
                label="Казахстан"
            />
            <FormControlLabel
                control={<Checkbox checked={conditions} onChange={handleConditionsChange} />}
                label="С условием"
            />
            <FormControlLabel
                control={<Checkbox checked={offerType} onChange={handleOfferTypeChange} />}
                label="Отправляем"
            />
        </Box>
    );
}

export default Promocodes;